import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import LandingHeader from "../components/LandingHeader/LandingHeader";
import WhatYouGet from "../components/WhatYouGet/WhatYouGet";
import AssessmentSchedule from "../components/AssessmentSchedule/AssessmentSchedule";
import ScheduleNotice from "../components/AssessmentSchedule/ScheduleNotice";
import Process from "../components/Process/Process";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";


class LandingPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Development Assessment | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <LandingHeader
          firstLine="Free 360"
          secondLine="Development Assessment"
        />
        <AssessmentSchedule />
        <WhatYouGet />
        <Process />
        <ScheduleNotice />
        <Footer />
      </Layout>
    );
  }
}

export default LandingPage;
